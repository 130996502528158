import { t } from '../languages';
// 根据子节点查找父节点
export function findP(id, list = [], result = []) {
    for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        // 找到目标
        if (item.adcode === id) {
            // 加入到结果中
            result.push(item.adcode);
            // 因为可能在第一层就找到了结果，直接返回当前结果
            if (result.length === 1) return result;
            return true;
        }
        // 如果存在下级节点，则继续遍历
        if (item.children) {
            // 预设本次是需要的节点并加入到最终结果result中
            result.push(item.adcode);
            const find = findP(id, item.children, result);
            // 如果不是false则表示找到了，直接return，结束递归
            if (find) {
                return result;
            }
            // 到这里，意味着本次并不是需要的节点，则在result中移除
            result.pop();
        }
    }
    // 如果都走到这儿了，也就是本轮遍历children没找到，将此次标记为false
    return false;
}

export function flatten(arr) {
    return Array.from(new Set(arr.flat(Infinity)));
}

export function isEqual(arr1, arr2) {
    return arr1.length === arr2.length && arr1.every((item, i) => {
        return item === arr2[i]
    })
}

//遍历tree取出adcode，
export function openRegionAdcode(data = [], arr = []) {
    for (let item of data) {
        arr.push(item.adcode);
        if (item.children && item.children.length) {
            openRegionAdcode(item.children, arr);
        }

    }
    return arr;
}
// 根据父adcode 查找区adcode
export function findChildren(id, data = [], arr = []) {
    for (let item of data) {
        if (item.children && item.adcode === id) {
            item.children.map(item => {
                arr.push(item.adcode)
            })
        }
        findChildren(id, item.children, arr)
    }
    return arr;
}

export function getAdcode(father, children) {
    return father.filter(f => children.indexOf(f) === -1);
}
export const cityMap = {
    1: t("7"),
    110000: t("8"),
    120000: t("9"),
    130000: t("10"),
    140000: t("11"),
    150000: t("12"),
    210000: t("13"),
    220000: t("14"),
    230000: t("15"),
    310000: t("16"),
    320000: t("17"),
    330000: t("18"),
    340000: t("19"),
    350000: t("20"),
    360000: t("21"),
    370000: t("22"),
    410000: t("23"),
    420000: t("24"),
    430000: t("25"),
    440000: t("26"),
    450000: t("27"),
    460000: t("28"),
    500000: t("29"),
    510000: t("30"),
    520000: t("31"),
    530000: t("32"),
    540000: t("33"),
    610000: t("34"),
    620000: t("35"),
    630000: t("36"),
    640000: t("37"),
    650000: t("38"),
    710000: t("39"),
    810000: t("40"),
    820000: t("41")
}
