<template>
  <div class="container">
    <!-- 头部 -->
    <div class="header">
      <DeviceHeader
        :navlist="navlist"
        :dialogType="dialogType"
        @addUAV="addUAV"
        @deviceDel="deviceDel"
        @searchChange="searchChange"
      />
      <!-- @cityChange="cityChange" @teamChange="teamChange" @modelChange="modelChange" -->
    </div>
    <div class="elTab">
      <ele-table
        :tableColumnArray="tableColumnArray"
        :tableData="tableData"
        v-model:pageNo="queryForm.pageNo"
        v-model:pageSize="queryForm.pageSize"
        v-model:total="queryForm.total"
        @handleNoChange="getOtherList"
        @handleSizeChange="getOtherList"
        @handleSelectionChange="handleSelectionChange"
      >
        <template v-slot:buttonHanlder>
          <el-table-column :label="t('610')" align="center" width="200px">
            <template v-slot="{ row, index }">
              <el-row>
                <el-col :span="8">
                  <span class="blue_btn" @click.stop="detail(row)">{{ t('391') }}</span>
                </el-col>
                <el-col :span="12">
                  <span class="blue_btn" @click="openRecord(row, index)"
                    >{{ t('623') }}</span
                  >
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </template>
      </ele-table>
    </div>

    <!-- 详情 -->
    <Detail
      v-if="showDetail"
      :editShow="editShow"
      :dialogType="dialogType"
      @closeDetail="closeDetail"
      @edit="edit"
      @editClose="editClose"
      @editCommit="editCommit"
      :detailData="detailData"
    />
  </div>
  <!-- 添加弹窗 -->
  <AddDevice
    v-if="addDeviceShow"
    v-model:isVisible="addDeviceShow"
    :editTitle="editTitle"
    :dialogType="dialogType"
    @addDevice="addDevice"
  />
  <!-- 删除 -->
  <DelDialog
    v-if="delShow"
    :dialogTitle="delDialogTitle"
    :isVisible="delShow"
    @on-add="delSubmit"
    @on-cancel="otherCancel"
  >
    <div><span class="del">!</span> {{ t('624') }}？</div>
  </DelDialog>

  <!-- 保险详情 -->
  <Insurance
    v-if="drawerVisible"
    :deviceId="deviceId"
    v-model:drawerVisible="drawerVisible"
  ></Insurance>
</template>

<script>
import { t } from '../../languages';
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import DeviceHeader from "../../components/common/device-header.vue";
import AddDevice from "./components/add-device.vue";
import Detail from "./components/detail.vue";
import DelDialog from "../../components/package/ele-dialog.vue";
import device from "../../network/device";
import { ElMessage } from "element-plus";
import Insurance from "./components/insurance.vue";
import { id } from "element-plus/lib/locale";

export default defineComponent({
  components: {
    DeviceHeader,
    AddDevice,
    Detail,
    DelDialog,
    Insurance,
  },
  setup() {
    //保险详情传参
    let deviceId = ref("");
    let drawerVisible = ref(false);

    const state = reactive({
      navlist: [
        { id: 1, name: t("164"), path: "/airport", type: "airport" },
        // { id: 2, name: t("165"), path: "/deviceManageIndex", type: "fly" },
        { id: 3, name: t("166"), path: "/otherAsset", type: "asset" },
      ],
      // 详情编辑
      editShow: false,
      //
      openShow: false,
      // 详情
      showDetail: false,
      detailData: {},
      // 添加
      addDeviceShow: false,
      editTitle: t("625"),
      dialogType: "asset",
      delShow: false,
      delDialogTitle: t("130"),
      // 分页
      queryForm: {
        modelId: "",
        queryInfo: "",
        region: "",
        teamId: "",
        pageNo: 1,
        pageSize: 10,
      },
      // 表头
      tableColumnArray: [
        { label: t("616"), prop: "accessoryName" },
        { label: t("617"), prop: "accessorySn" },
        { label: t("215"), prop: "modelName" },
        { label: t("618"), prop: "accessoryNumber" },
        { label: t("626"), prop: "deviceName" },
        { label: t("212"), prop: "region" },
        { label: t("214"), prop: "teamName" },
      ],
      // 表体数据
      tableData: [],
      // 作业信息
      jobInformation: [{}],
      //单选多选删除ids集合
      ids: [],
      // 选中删除数据
      delArr: [],
    });
    onMounted(() => {
      methods.getOtherList();
      document.querySelector(".container").onclick = function () {
        if (state.showDetail&&!state.editShow) {
          state.showDetail = false;
        }
      };
    });
    const methods = reactive({
      // 打开详情页
      detail: (row) => {
        state.detailData = row;
        state.showDetail = true;
      },
      // 关闭
      closeDetail: () => {
        state.showDetail = false;
        state.editShow = false;
      },
      // 开启录像
      openRecord: (row, index) => {
        // state.openShow = !state.openShow;
        // state.tableData.open = !state.tableData.open;
        deviceId.value = row.id;
        drawerVisible.value = true;
      },
      // 详情编辑
      edit: () => {
        state.editShow = !state.editShow;
      },
      // 关闭编辑
      editClose: () => {
        state.editShow = false;
      },
      // 提交编辑数据
      editCommit: () => {
        state.editShow = false;
        methods.getOtherList();
      },
      // 添加数据
      addUAV: () => {
        state.addDeviceShow = !state.addDeviceShow;
      },
      // 删除
      deviceDel: () => {
        if (state.delArr == "") {
          ElMessage.warning(t("620"));
        } else {
          state.ids = []; //去除上一次数据
          state.delArr.map((item) => state.ids.push(item.id));
          state.delShow = !state.delShow;
        }
      },
      // 多选确认删除
      delSubmit: async () => {
        let ids = state.ids;
        let res = await device.delAccessory({ ids });
        if (res.resultStatus) {
          ElMessage.success(t("560"));
          methods.getOtherList();
          state.delShow = false;
          // state.queryForm.pageNo = 1;
          // state.queryForm.pageSize = 10;
        }
      },
      otherCancel: () => {
        state.delShow = false;
      },
      // 头部搜索数据
      searchChange: (e) => {
        state.queryForm = { ...e };
        methods.getOtherList();
      },
      //默认数据列表
      getOtherList: async () => {
        let res = await device.getAccessoryList(state.queryForm);
        if (res.resultStatus) {
          state.tableData = res.resultData.data;
          state.queryForm.total = res.resultData.total;
        }
      },
      // 新增刷新页面
      addDevice: () => {
        state.addDeviceShow = false;
        methods.getOtherList();
      },
      // 多选事件
      handleSelectionChange: (e) => {
        state.delArr = e;
      },
    });

    return {
      ...toRefs(state),
      ...toRefs(methods),
      deviceId,
      drawerVisible,
    };
  },
});
</script>

<style >
</style>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background: rgba(0, 28, 49, 0.8);
}
.el-table tr {
  background-color: none !important;
  font-size: 16px;
}
.header {
  height: 7.125rem;
}
.elTab {
  padding: 0 2.0625rem;
  box-sizing: border-box;
  height: calc(100% - 7.125rem);
  background: rgba(0, 28, 49, 0.8);
  /deep/ .el-table tr {
    background-color: none !important;
  }
  /deep/ .el-table__body {
    box-sizing: border-box;
    overflow: hidden;
    width: 100% !important;
  }
  //body边距
  /deep/ .el-table__row {
    height: 3.75rem !important;
    border-radius: 0 8px 8px 0 !important;
  }
  /deep/tr .el-table__row {
    border-radius: 0 8px 8px 0 !important;
  }
  /deep/ .table-wrapper {
    position: initial;
  }
  // 分页
  /deep/ .el_pageination {
    right: 2.125rem;
  }
  /deep/ .el_pageination .el-input_wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }
  //下拉背景图片
  /deep/ .el-select .el-input__wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }
  /deep/ .el-input--small .el-input__wrapper {
    background: rgba(0, 28, 49, 0.8) !important;
  }
}
.del {
  display: inline-block;
  background: #e6a33d;
  color: #ffffff;
  width: 1.625rem;
  height: 1.625rem;
  line-height: 1.625rem;
  text-align: center;
  border-radius: 50%;
  margin-right: 0.9375rem;
}
</style>