<!-- 保险详情抽屉组件 -->
<template>
  <ele-drawer
    :drawerTitle="t('623')"
    :isVisible="drawerVisible"
    :drawerWidth="625"
    @on-close="closeDrawer"
  >
    <div class="addButton">
      <img
        class="hover_opacity"
        src="@/asset/img/personal-icon/upload.png"
        @click="addFly"
      />
      <span
        class="hover_opacity"
        style="padding-left: 0.5625rem"
        @click="addFly"
        >{{ t('1119') }}</span
      >
    </div>
    <div
      class="addCertificate"
      v-if="isAdd"
      v-loading="addLoading"
      :element-loading-text="t('1120')"
      element-loading-background="rgba(0, 67, 117, 0.8)"
    >
      <div>
        <span>{{ t('1121') }}：</span>
        <input
          v-model="certificate.insuranceName"
          @blur="checkInput"
          onkeyup="this.value=this.value.replace(/\ +/g, '')"
          maxlength="20"
        />
        <transition name="el-zoom-in-top">
          <p class="error-tip" v-if="tipshow">{{ t('1122') }}</p>
        </transition>
      </div>
      <div>
        <span>{{ t('864') }}：</span>
        <div class="timeSelect">
          <el-date-picker
            v-model="certificate.deadtime"
            type="date"
            :placeholder="t('774')"
            value-format="YYYY-MM-DD"
            @change="checkDate"
          />
        </div>
      </div>
      <transition name="el-zoom-in-top">
        <p class="error-tip" v-if="tipshow1">{{timeText}}</p>
      </transition>
      <div class="uploadImg">
        <div class="title">{{ t('1123') }}：</div>
        <el-upload
          accept=".jpg,.png,.svg"
          list-type="picture-card"
          :file-list="photoUrl"
          :before-upload="beforeAvatarUpload"
          :auto-upload="false"
          :limit="4"
          multiple
          :on-exceed="overTips"
          @change="checkPhoto"
          :on-remove="checkPhoto"
        >
          <el-icon><CirclePlus /></el-icon>
          <div>{{ t('575') }}</div>
        </el-upload>
      </div>
      <transition name="el-zoom-in-top">
        <p class="error-tip" v-if="tipshow2">{{ t('1124') }}</p>
      </transition>
      <div class="flyButton">
        <el-button @click="cancel">{{ t('217') }}</el-button>
        <el-button
          style="background: rgba(0, 112, 195, 0.7)"
          @click="addCertificated"
        >
          {{ t('284') }}
        </el-button>
      </div>
    </div>
    <!-- 保险列表 -->
    <div
      class="flyContent"
      style="border-top: 1px solid #0085e9"
      v-for="(item, index) in insuranceList"
      :key="index"
      v-show="!insuranceList[0].emptyList"
      v-loading="isEditid === item.id && updateLoading"
      :element-loading-text="t('1125')"
      element-loading-background="rgba(0, 67, 117, 0.8)"
    >
      <div style="display: inline-block">
        <span>{{ t('1121') }}：</span>
        <input
          v-model="updatedFly.insuranceName"
          @input="updateInput"
          onkeyup="this.value=this.value.replace(/\ +/g, '')"
          v-if="isEditid === item.id"
          maxlength="20"
        />
        <div v-else style="display: inline-block">
          <el-tooltip
            effect="dark"
            placement="top"
            :content="item.insuranceName"
            v-if="item.insuranceName.length > 14"
          >
            <span class="contenBox"
              >{{ item.insuranceName.substr(0, 14) + "..." }}
            </span>
          </el-tooltip>
          <span class="contenBox" v-else>{{ item.insuranceName }} </span>
        </div>

        <transition name="el-zoom-in-top">
          <p class="error-tip" v-if="tipshow3 && isEditid === item.id">
            {{ t('1122') }}
          </p>
        </transition>
      </div>
      <div
        class="flyEdit hover_opacity"
        v-if="isEditid !== item.id"
        @click="updateInsurance(item.id)"
      >
        <img src="@/asset/img/personal-icon/edit.png" /><span>{{ t('674') }}</span>
      </div>
      <div
        class="flyEdit hover_opacity"
        v-if="isEditid !== item.id"
        @click="deleteFlyCertificate(item.id)"
      >
        <img src="@/asset/img/personal-icon/delete.png" /><span>{{ t('210') }}</span>
      </div>
      <div>
        <span>{{ t('864') }}：</span>

        <div class="timeSelect" v-if="isEditid === item.id">
          <el-date-picker
            v-model="updatedFly.deadtime"
            type="date"
            :placeholder="t('774')"
            value-format="YYYY-MM-DD"
            @change="updatekDate"
          />
        </div>

        <span class="contenBox" v-else>{{ item.deadtime }} </span>
        <span
          v-if="timer[index] < 0"
          class="iconfont icon-gantanhao2"
          style="margin-left: 10px; color: red"
          >&nbsp;{{ t('868') }}</span
        >
        <span
          v-if="timer[index] < 30 && timer[index] >= 0"
          class="iconfont icon-gantanhao2"
          style="margin-left: 10px; color: #fce83b"
          >&nbsp;{{ t('869') }}</span
        >
      </div>
      <transition name="el-zoom-in-top">
        <p class="error-tip" v-if="tipshow4 && isEditid === item.id">
          {{timeText}}
        </p>
      </transition>
      <div class="uploadImg">
        <div class="title">{{ t('1123') }}：</div>
        <el-upload
          action=""
          accept=".jpg,.png,.svg"
          list-type="picture-card"
          :file-list="updatePhotoUrl"
          :before-upload="beforeAvatarUpload"
          :auto-upload="false"
          v-if="isEditid === item.id"
          :limit="4"
          :on-exceed="overTips"
          multiple
          @change="updatePhoto"
          :on-remove="updatePhoto"
        >
          <el-icon><CirclePlus /></el-icon>
          <div>{{ t('575') }}</div>
        </el-upload>
        <div v-else class="imgList">
          <div
            v-for="url in item.imgList"
            :key="url"
            style="position: relative"
          >
            <el-image :src="url" lazy> </el-image>
            <img
              v-if="timer[index] < 0"
              class="overdue"
              src="../../../asset/img/overdue.png"
            />
          </div>
          <!-- <div
            class="photoList"
            v-for="(imgUrl, index1) in item.imgList"
            :key="index1"
          >
            <div>
              <img :src="imgUrl" />
             
              <img
                class="overdue"
                src="../../../asset/img/overdue.png"
                v-if="timer[index] < 0"
              />
            </div>
          </div> -->
        </div>
      </div>
      <transition name="el-zoom-in-top">
        <p class="error-tip" v-if="tipshow5 && isEditid === item.id">
          {{ t('1126') }}
        </p>
      </transition>
      <div class="flyButton" v-if="isEditid === item.id">
        <el-button @click="updateCancel">{{ t('217') }}</el-button>
        <el-button
          style="background: rgba(0, 112, 195, 0.7)"
          @click="saveFlyCertificate"
          >{{ t('284') }}</el-button
        >
      </div>
    </div>
  </ele-drawer>
</template>
<script>
import { t } from '../../../languages';
import { CirclePlus } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { reactive, toRefs, defineComponent, ref, onBeforeMount } from "vue";
import deviceService from "@/network/device";
import common from "@/network/common";
export default defineComponent({
  props: {
    deviceId: {
      type: String,
      default: "",
    },
    drawerVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { deviceId, drawerVisible } = toRefs(props);
    //保险详情--抽屉
    const insuranceFun = {
      closeDrawer: function () {
        //关闭保险详情
        emit("update:drawerVisible", false);
      },
    };

    /* 图片上传列表 */
    const photoUrl = ref([]);
    const updatePhotoUrl = ref([]);

    /*保险初始化参数列表  */
    let certicateParmas = {
      id: "", //保险id
      deviceId: "", //设备id
      deadtime: "", //选泽保险时间
      insuranceName: "", //保险名字
      emptyList: true,
      photoUrl: "", //图片字符串
      imgList: [], //图片数组
    };

    /* 获取飞行保险列表 */
    // const { deviceId } = toRefs(props);
    async function initInsurancList() {
      data.insuranceList = [Object.assign({}, certicateParmas)];
      await deviceService
        .getInsuranceList(deviceId.value)
        .then((res) => {
          if (res.resultData) {
            data.insuranceList = [...res.resultData];
            console.log(t("1127"), data.insuranceList, res.resultData);
            timer.value = [];
            data.insuranceList.forEach((item) => {
              item.deadtime = item.deadtime.slice(0, 10);
              item.imgList = item.photoUrl.split(";");
              const nowTime = timestampToTime(new Date().getTime());
              diffTimeHour(nowTime, item.deadtime);
            });
          }
        })
        .catch((err) => {
          console.log(t("1127"), err);
          ElMessage.error(t("1128"));
        });
    }

    /* 初始化列表 */
    onBeforeMount(() => {
      initInsurancList();
    });

    let data = reactive({
      isEditid: "", //判断是否编辑
      isAdd: false, //判断是否添加保险
      addLoading: false, //添加时加载动画
      updateLoading: false, //修改时加载动画
      certificate: Object.assign({}, certicateParmas),
      insuranceList: [Object.assign({}, certicateParmas)], //保险列表
      updatedFly: Object.assign({}, certicateParmas), //修改时获取的保险数据
    });

    /* 检查保险上传的文件类型 */
    const beforeAvatarUpload = (rawFile) => {
      let imgType = ["image/jpg", "image/png", "image/svg"];
      if (!imgType.includes(rawFile.type)) {
        ElMessage.error("Avatar picture must be JPG format!");
        return false;
      }
      return true;
    };

    /* 添加保险事件 */
    function addFly() {
      data.certificate = Object.assign({}, certicateParmas);
      data.isAdd = true;
      photoUrl.value = [];
    }
    /* 请求参数统一化 */
    function requestParams(urlList, flyData) {
      let params = {
        insuranceName: flyData.insuranceName,
        deadtime: flyData.deadtime,
        id: flyData.id,
        deviceId: deviceId.value,
        photoUrl: urlList.join(";"),
      };
      return params;
    }
    /* 保存添加保险 */
    const timeText = ref(t("870")); 
    const tipshow = ref(false);
    const tipshow1 = ref(false);
    const tipshow2 = ref(false);
    const checkInput = () => {
      //检查是否填写名称
      if (data.certificate.insuranceName.trim() !== "") {
        tipshow.value = false;
      } else {
        tipshow.value = true;
      }
    };
    const checkDate = (e) => {
      let time = +new Date();
      if(e!=null||data.certificate.deadtime!=""){
        // if (data.certificate.deadtime !== "") {
        //   tipshow1.value = false;
        // } else {
        //   console.log(222);
        //   tipshow1.value = true;
        // }
        if(data.certificate.deadtime!=null){
            if(+new Date(e) + 86400000 - 1000 < time){
              timeText.value = t("871");
              tipshow1.value = true;
            }
            if(+new Date(e) + 86400000 - 1000 > time){
              tipshow1.value = false;
            }
        }else{
            timeText.value = t("870");
            tipshow1.value = true;
          }
      }else{
        timeText.value = t("870");
        tipshow1.value = true;
      }
      
    };
    const checkPhoto = () => {
      if (photoUrl.value.length !== 0) {
        tipshow2.value = false;
      } else {
        tipshow2.value = true;
      }
    };
    const overTips = () => {
      ElMessage.info(t("1129"));
    };

    async function addCertificated() {
      if(tipshow1.value||tipshow2.value)return;
      if (
        !data.certificate.insuranceName.trim() ||
        data.certificate.deadtime === "" ||
        data.certificate.deadtime === null ||
        !photoUrl.value.length
      ) {
        checkDate();
        checkInput();
        checkPhoto();
        return;
      }
      data.addLoading = true;
      const urlList = [];
      for (let i = 0; i < photoUrl.value.length; i++) {
        let res = await common
          .uploadFiles(
            { file: photoUrl.value[i].raw, systemName: "user" },
            true
          )
          .catch(() => {
            data.addLoading = false;
            ElMessage.error(t("1130"));
          });
        if (res.resultStatus === false) {
          data.addLoading = false;
          return;
        }
        urlList.push(res.resultData.url);
      }
      let params = requestParams(urlList, data.certificate);
      deviceService
        .addInsuranc(params)
        .then((res) => {
          if(res.resultStatus){
            ElMessage.success(t("1131"));
            data.isAdd = false;
            initInsurancList();
          }
          tipshow.value = false;
          data.addLoading = false;
        })
        .catch(() => {
          data.addLoading = false;
          ElMessage.error(t("1130"));
        });
    }
    function cancel() {
      //关闭
      data.isAdd = false;
      tipshow.value = false;
      tipshow1.value = false;
      tipshow2.value = false;
    }

    /* 获取编辑保险信息 */
    async function updateInsurance(id) {
      if (data.updateLoading) {
        ElMessage.warning(t("1132"));
        return;
      }
      data.updatedFly = Object.assign({}, certicateParmas);
      updatePhotoUrl.value = [];
      let time = +new Date();
      await deviceService.editInsurance(id).then(
        (res) => {
          if (res.resultData) {
            data.updatedFly = res.resultData;
            data.updatedFly.deadtime = res.resultData.deadtime.slice(0, 10);
            const photoUrlList = res.resultData.photoUrl.split(";");
            photoUrlList.forEach((item) => {
              updatePhotoUrl.value.push({ name: t("877"), url: item });
            });
            if(+new Date(data.updatedFly.deadtime) + 86400000 - 1000 < time){
              timeText.value = t("871");
              tipshow4.value = true;
            }else{
              tipshow4.value = false;
            }
          }
          console.log(updatePhotoUrl.value);
        },
        () => {
          ElMessage.error(t("1133"));
        }
      );
      data.isEditid = id;
    }

    //时间戳转化为时间格式xxxx-xx-xx
    function timestampToTime(timestamp) {
      let date = new Date(timestamp);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      return Y + M + D;
    }
    //比较两个时间差
    const timer = ref([]); //存时间差
    function diffTimeHour(oldTime, newTime) {
      const timeDiff =
        new Date(newTime.replace(/-/g, "/")).getTime() -
        new Date(oldTime.replace(/-/g, "/")).getTime();
      const formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      timer.value.push(formatTimeDiff);
      console.log(timer.value);
    }

    /* 保存修改的保险 */
    const tipshow3 = ref(false);
    const tipshow4 = ref(false);
    const tipshow5 = ref(false);

    const updateInput = () => {
      if (data.updatedFly.insuranceName.trim() !== "") {
        tipshow3.value = false;
      } else {
        tipshow3.value = true;
      }
    };

    const updatekDate = (e) => {
      let time = +new Date();
      if(e!=null||data.updatedFly.deadtime!==""){
        if(data.updatedFly.deadtime!=null){
          if(+new Date(e) + 86400000 - 1000 < time){
            timeText.value = t("871");
            tipshow4.value = true;
          }
          if(+new Date(e) + 86400000 - 1000 > time){
            tipshow4.value = false;
          }
        }else{
          timeText.value = t("870");
          tipshow4.value = true;
        }
      }else{
        timeText.value = t("870");
        tipshow4.value = true;
      }
    };

    const updatePhoto = () => {
      if (updatePhotoUrl.value.length !== 0) {
        tipshow5.value = false;
      } else {
        tipshow5.value = true;
      }
    };
    async function saveFlyCertificate() {
      if(tipshow3.value||tipshow4.value)return;
      if (
        !updatePhotoUrl.value.length ||
        !data.updatedFly.insuranceName.trim() ||
        !data.updatedFly.deadtime === null
      ) {
        updateInput();
        updatekDate();
        updatePhoto();
        return;
      }
      const updateImglist = [];
      data.updateLoading = true;
      for (let i = 0; i < updatePhotoUrl.value.length; i++) {
        if (updatePhotoUrl.value[i].name === t("877")) {
          updateImglist.push(updatePhotoUrl.value[i].url);
        } else {
          //图片上传到服务器放回url
          let res = await common
            .uploadFiles(
              { file: updatePhotoUrl.value[i].raw, systemName: "user" },
              true
            )
            .catch(() => {
              data.updateLoading = false;
              ElMessage.error(t("1134"));
            });
          if (res.resultStatus === false) {
            data.updateLoading = false;
            return;
          }
          updateImglist.push(res.resultData.url);
        }
      }
      let params = requestParams(updateImglist, data.updatedFly);
      await deviceService
        .saveInsuranc(params)
        .then((res) => {
          res.resultStatus && ElMessage.success(t("1135"));
        })
        .catch((error) => {
          console.log(error);
          ElMessage.error(t("1134"));
        });
      data.updateLoading = false;
      data.isEditid = "";
      initInsurancList();
    }
    //修改取消
    function updateCancel() {
      data.isEditid = "";
      tipshow3.value = false;
      tipshow4.value = false;
      tipshow5.value = false;
    }

    /* 删除保险 */
    function deleteFlyCertificate(id) {
      deviceService.deleteInsuranc(id).then((res) => {
        res.resultStatus && ElMessage.success(t("1136"));
        initInsurancList();
      });
    }

    return {
      /* 数据 */
      ...toRefs(data),
      timer,
      photoUrl,
      updatePhotoUrl,
      tipshow,
      tipshow1,
      tipshow2,
      tipshow3,
      tipshow4,
      tipshow5,
      /* 函数 */
      beforeAvatarUpload,
      addFly,
      addCertificated,
      cancel,
      updateInsurance,
      saveFlyCertificate,
      deleteFlyCertificate,
      diffTimeHour,
      timestampToTime,
      overTips,
      checkDate,
      checkInput,
      checkPhoto,
      updateInput,
      updatekDate,
      updatePhoto,
      updateCancel,
      ...insuranceFun,
      /* 图标 */
      CirclePlus,
      timeText
    };
  },
});
</script>
<style lang="less" scoped>
.el-button {
  color: #ffffff;
}
.el-drawer__body {
  padding: 0px !important;
}
.addButton {
  background: rgba(0, 112, 195, 0.4);
  height: 5rem;
  line-height: 5rem;
  border-top: solid #0085e9 0.0938rem;
  border-bottom: solid #0085e9 0.125rem;
  text-align: center;
  img {
    width: 1.25rem;
    vertical-align: middle;
  }
}
.flyEdit {
  text-align: right;
  margin-right: 1.125rem;
  margin-top: 0;
  span {
    margin-right: 0.9375rem;
    margin-left: 0.6875rem;
  }
}
.flyContent,
.addCertificate {
  padding-left: 3.5625rem;
  text-align: left;
  position: relative;

  .error-tip {
    color: #f56c6c;
    font-size: 0.875rem;
    position: absolute;
    left: 10.5625rem;
  }
  & > div {
    padding-top: 2.625rem;
    .contenBox {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #00deff;
      margin-right: 0 !important;
    }
  }
  span:first-child {
    margin-right: 2rem;
  }
  .flyEdit {
    float: right;
    span {
      margin-right: 0.9375rem;
      margin-left: 0.6875rem;
    }
  }
  .uploadImg {
    display: flex;
    .title {
      flex-shrink: 0;
      margin-right: 4rem;
    }
    .imgList {
      display: flex;
      flex-wrap: wrap;
      // .photoList {
      //   position: relative;
      //   img {
      //     width: 9.9375rem;
      //     height: 6.625rem;
      //     margin-right: 1.75rem;
      //     margin-bottom: 2.625rem;
      //     vertical-align: top;
      //     object-fit: cover;
      //   }
      // }
      .el-image {
        width: 9.9375rem;
        height: 6.625rem;
        margin-right: 1.75rem;
        margin-bottom: 2.625rem;
        vertical-align: top;
      }
      .overdue {
        position: absolute;
        top: 1.5625rem;
        left: 3.125rem;
        width: 3.125rem;
        height: 3.125rem;
      }
    }
    .el-icon {
      font-size: 2.5rem;
      color: #00f5ff;
      text-align: center;
    }
  }
  .flyButton {
    text-align: right;
    margin-right: 2.25rem;
    padding-top: 0;
  }
  .el-button {
    width: 6.0625rem;
    height: 2.875rem;
    background: rgba(0, 28, 49, 0.7);
    border: 0;
    margin-bottom: 2.375rem;
  }
  .timeSelect {
    position: relative;
    padding: 0;
    display: inline-block;

    /deep/.el-input__wrapper {
      background: rgba(0, 28, 49, 0.7);
      border: 1px solid #00a1a8;
      height: 2.875rem;
      width: 21.625rem;
    }
    /deep/.el-input__inner {
      color: #ffffff;
    }
  }
  input {
    width: 21.25rem;
    height: 2.875rem;
    background: rgba(0, 28, 49, 0.7);
    border: 1px solid #00a1a8;
    color: #ffffff;
  }
}
</style>
